<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'historico.index',
                  params: { aluno_id: aluno_id },
                })
            "
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Histórico do Aluno
        </h1>
      </v-col>
    </v-row>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="py-0" cols="8">
            <e-label>Aluno</e-label>
            <v-text-field v-model="selectedStudent" label="Aluno" dense solo disabled />
          </v-col>
          <v-col class="py-0" cols="4">
            <ValidationProvider name="INEP" rules="required" v-slot="{ errors }">
              <e-label>Identificação única (INEP)</e-label>
              <v-text-field
                :error-messages="errors"
                v-model="inep"
                label="INEP"
                type="number"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <v-col class="py-0" cols="4">
            <ValidationProvider name="Escola" rules="required" v-slot="{ errors }">
              <e-label>Escola</e-label>
              <v-text-field :error-messages="errors" v-model="escola" label="Escola" dense solo />
            </ValidationProvider>
          </v-col>

          <v-col class="py-0" cols="4">
            <ValidationProvider name="Município" rules="required" v-slot="{ errors }">
              <e-label>Município</e-label>
              <v-text-field
                :error-messages="errors"
                v-model="municipio"
                label="Município"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <v-col class="py-0" cols="4">
            <ValidationProvider name="Estado" rules="required" v-slot="{ errors }">
              <e-label>Estado</e-label>
              <v-text-field :error-messages="errors" v-model="estado" label="Estado" dense solo />
            </ValidationProvider>
          </v-col>

          <v-col class="py-0" cols="4">
            <ValidationProvider
              name="Ano de Conclusão"
              rules="required|numeric"
              v-slot="{ errors }"
            >
              <e-label>Ano de conclusão</e-label>
              <v-text-field
                :error-messages="errors"
                v-model="ano"
                label="Ano"
                type="number"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <v-col class="py-0" cols="4">
            <ValidationProvider name="Série" rules="required" v-slot="{ errors }">
              <e-label>Série</e-label>
              <e-autocomplete
                :error-messages="errors"
                label="Série"
                :items="seriesList"
                v-model="serieId"
                :item-value="(value) => value.id"
                :item-text="(value) => value.descricao"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <v-col class="py-0" cols="4">
            <ValidationProvider name="Modalidade" rules="required" v-slot="{ errors }">
              <e-label>Modalidade</e-label>
              <v-text-field
                :error-messages="errors"
                v-model="modalidade"
                label="Ensino Regular / EJA"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <v-col class="py-0" cols="4">
            <e-label>Disciplinas</e-label>
            <e-autocomplete
              v-for="(disciplina, index) in disciplinas"
              label="Disciplina"
              :key="index"
              :items="disciplinasList"
              v-model="disciplinas[index]"
              :item-value="(item) => item.id"
              :item-text="(item) => item.descricao"
              dense
              solo
            />
          </v-col>
          <v-col class="py-0" cols="4">
            <e-label>Médias</e-label>
            <v-text-field
              v-for="(nota, index) in medias"
              :key="index"
              v-model="medias[index]"
              label="Média"
              dense
              solo
              type="text"
              :data-index="index"
              @input="formatNota(index)"
              @blur="validateValue(index)"
              @keydown="preventInvalidInput"
            />
          </v-col>
          <v-col class="py-0" cols="4">
            <e-label>&nbsp;</e-label>
            <v-btn
              v-for="(nota, index) in medias"
              :key="index"
              @click.prevent="removeField(index)"
              class="mb-7"
              color="error"
              type="submit"
              block
            >
              Excluir
            </v-btn>
          </v-col>
          <v-col class="pb-7 pt-0" cols="3">
            <v-btn @click.prevent="addField" color="primary" type="submit" block>
              Adicionar disciplina
            </v-btn>
          </v-col>
          <v-col class="py-0" cols="5">
            <e-autocomplete
              label="Situação"
              :items="AprovadoReprovado"
              v-model="situacao"
              :item-value="(value) => value.id"
              :item-text="(value) => value.descricao"
              dense
              solo
            />
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Salvar</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { $toast } from "vuetify-snackbar-toast";
import GeradorDadosHistorico from "@/utils/geradorDadosHistorico";

export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    await this.loadData();
  },
  data() {
    return {
      submittingForm: false,
      id: "",
      aluno_id: "",
      selectedStudent: "",
      escola: "",
      municipio: "",
      estado: "",
      inep: "",
      ano: "",
      modalidade: "",
      disciplinas: [],
      disciplinasList: [],
      seriesList: [],
      medias: [],
      serieId: null,
      situacao: null,
      AprovadoReprovado: [
        {
          id: 1,
          descricao: "Aprovado",
        },
        {
          id: 2,
          descricao: "Reprovado",
        },
      ],
    };
  },
  methods: {
    async submitForm() {
      this.$loaderService.open("Salvando dados");
      if (!this.formIsValid()) {
        $toast.danger("Preencha a disciplina junto à nota final");
        this.$loaderService.close();
        return;
      }
      if (
        this.disciplinas[this.disciplinas.length - 1] === " " &&
        this.medias[this.medias.length - 1] === " "
      ) {
        this.disciplinas.pop();
        this.medias.pop();
      }
      const historico = {
        id: this.id,
        aluno_id: this.aluno_id,
        escola: this.escola,
        municipio: this.municipio,
        estado: this.estado,
        inep: this.inep,
        ano: this.ano,
        serie: this.serieId,
        modalidade: this.modalidade,
        disciplinas: this.disciplinas,
        medias: this.medias,
        situacao: this.situacao,
      };
      try {
        if (this.editing) {
          await this.$services.historicoService.atualizarHistorico(historico);
        } else {
          await this.$services.historicoService.criarHistorico(historico);
        }
        this.$router.push({
          name: "historico.index",
          params: { aluno_id: this.aluno_id },
        });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    addField() {
      if (
        this.disciplinas[this.disciplinas.length - 1] !== " " &&
        this.medias[this.medias.length - 1] !== " "
      ) {
        this.disciplinas.push(" ");
        this.medias.push(" ");
      } else {
        $toast.danger("É necessário preencher a anterior para cadastrar a próxima");
      }
    },
    removeField(index) {
      this.disciplinas.splice(index, 1);
      this.medias.splice(index, 1);
    },
    formIsValid() {
      if (this.disciplinas.length === 0 || this.medias.length === 0 || !this.situacao) {
        return false;
      }

      let flag = true;

      this.disciplinas.forEach((item) => {
        if (item === null || item.length < 1) {
          flag = false;
        }
      });

      this.medias.forEach((item) => {
        if (item === null || item.length < 1) {
          flag = false;
        }
      });

      this.medias.forEach((item) => {
        if (item === null || item.length < 1) {
          flag = false;
        }
      });

      return flag;
    },
    async loadData() {
      this.disciplinasList = await this.$services.disciplinasService.syncAll();
      this.seriesList = await this.$services.seriesService.syncAll();
      if (this.editing) {
        const historico = await this.pegarHistorico();
        this.id = historico.id;
        this.selectedStudent = historico.aluno.nomecompleto;
        this.aluno_id = historico.aluno.id;
        this.escola = historico.escola;
        this.municipio = historico.municipio;
        this.estado = historico.estado;
        this.inep = historico.inep;
        this.ano = historico.ano;
        this.serieId = Number(historico.serie);
        this.modalidade = historico.modalidade;
        this.disciplinas = historico.disciplinas;
        this.medias = historico.medias;
        this.situacao = historico.situacao;
      } else {
        const aluno = await this.$services.historicoService.pegarAluno(
          this.$router.currentRoute.params.aluno_id
        );
        this.selectedStudent = aluno.nomecompleto;
        this.aluno_id = aluno.id;
        this.addField();
      }
    },
    async pegarHistorico() {
      try {
        const { historico_id } = this.$route.params;
        const historico = await this.$services.historicoService.pegarHistorico(historico_id);
        return historico[0][0];
      } catch (error) {
        return this.$handleError(error);
      }
    },
    formatNota(index) {
      const value = this.medias[index].replace(/[^0-9]/g, "");

      if (value.length === 0) {
        this.$set(this.medias, index, "");
        return;
      }

      let numericValue = parseInt(value, 10) / 10;

      if (numericValue > 10) {
        numericValue = 10;
      }

      this.$set(this.medias, index, numericValue.toFixed(1));
    },

    validateValue(index) {
      let numericValue = parseFloat(this.medias[index]);

      if (isNaN(numericValue)) {
        this.$set(this.medias, index, "");
        return;
      }

      if (numericValue > 10) {
        numericValue = 10;
      }

      if (numericValue < 0) {
        numericValue = 0;
      }

      this.$set(this.medias, index, numericValue.toFixed(1));
    },

    preventInvalidInput(event) {
      const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];

      if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style></style>
