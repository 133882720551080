<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Disciplinas </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="criarDisciplina" class="mr-2">Criar Disciplina</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="disciplinasList"
          :loading="carregandoDisciplinas"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="disciplina.estaDeletado ? ['deleted'] : []"
                v-for="disciplina of items"
                :key="disciplina.id"
              >
                <td>{{ disciplina.id }}</td>
                <td>{{ disciplina.codigo ? disciplina.codigo : "- - -" }}</td>
                <td>{{ disciplina.descricao }}</td>
                <!-- <td>{{ disciplina.contexto }}</td> -->
                <td>
                  <v-tooltip v-if="!disciplina.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarDisciplina(disciplina)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar disciplina</span>
                  </v-tooltip>
                  <v-tooltip v-if="!disciplina.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarDisciplina(disciplina)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar disciplina</span>
                  </v-tooltip>
                  <v-tooltip v-if="disciplina.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarDisciplina(disciplina)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar disciplina</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  computed: {
    ...mapGetters("Disciplinas", ["disciplinasList", "carregandoDisciplinas"]),
  },
  data() {
    return {
      dataTableFilter,
      search: "",
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Código", value: "codigo" },
          { text: "Nome", value: "descricao" },
          // { text: "Contexto", value: "contexto" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  mounted() {
    this.listarDisciplinas();
  },
  watch: {
    carregandoDisciplinas(value) {
      if (value && this.disciplinasList.length === 0) {
        this.$loaderService.open("Carregando disciplinas");
      } else {
        this.$loaderService.close();
      }
    },
  },
  methods: {
    async listarDisciplinas() {
      await this.$store.dispatch("Disciplinas/loadDisciplinas");
    },
    criarDisciplina() {
      this.$router.push({ name: "disciplinas.create" });
    },
    editarDisciplina(disciplina) {
      this.$router.push({ name: "disciplinas.edit", params: { disciplina_id: disciplina.id } });
      // return disciplina;
    },
    async deletarDisciplina(disciplina) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta disciplina?",
        confirmationCode: disciplina.id,
        confirmationMessage: `Por favor, digite <strong>${disciplina.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Disciplina");
              try {
                await this.$services.disciplinasService.deletarDisciplina(disciplina);
                this.$toast.success("Disciplina deletada com sucesso");
                await this.listarDisciplinas();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarDisciplina(disciplina) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta disciplina?",
        confirmationCode: disciplina.id,
        confirmationMessage: `Por favor, digite <strong>${disciplina.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Disciplina");
              try {
                await this.$services.disciplinasService.recuperarDisciplina(disciplina);
                this.$toast.success("Disciplina recuperada com sucesso");
                await this.listarDisciplinas();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
